.currentPlayerDisplay {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0.5rem;
    padding: 0.5rem;
    border: 1px solid #000;
    border-radius: 0.5rem;
    background-color: green;
    color: #000;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;

    .white,
    .black {
        border-radius: 0.5rem;
        width: 50%;
    }

    .white {
        background-color: white;
        color: black;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .black {
        background-color: black;
        color: white;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .playerTurn {
        display: block;
        width: 100%;
    }
}

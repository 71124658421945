body {
    background-color: #323232;
    color: #e0e0e0;
}

.App {
    text-align: center;
}

h1, h2, h3 {
    color: #f0f0f0;
}

a {
    color: green;
}

.header {
    position: fixed;
    top: 0;
    height: 50px;
    background-color: #646464;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    left: 0px;
    right: 0px;
    z-index: 100;
}

.header img {
    height: 40px;
}

.title {
    margin: 0;
    font-size: 1.5rem;
    color: #e0e0e0;
}

.content {
    margin-top: 50px;
    padding: 10px;
}

.boardComponent {
    position: relative;
    width: 100%;

    table {
        border-color: black;
        border-width: 10px;
        user-select: none;
        width: 100%;
    }

    td {
        position: relative;
    }

    .boardOverlay {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        display: none;

        .boardOverlayContent {
            margin: auto;
            background-color: #646464;
            border: 1px solid rgba(250, 250, 253,1);
            border-radius: 10px;
            padding: 5px 5px 5px 5px;

            h3 {
                margin-block: 0.25em;
            }

            button {
                margin-block: 0.25em;
            }
        }
    }

    &.gameOver .gameOverOverlay {
        display: flex
    }

    &.forcedPass .forcedPassOverlay {
        display: flex
    }

    td {
        background-color: #00ff00;
        width: 50px;
        height: 50px;
    }

    .piece {
        display: block;
        height: 35px;
        width: 35px;
        border-radius: 50%;
        border: 5px solid;
        margin: auto;
    }

    .piece.white {
        background-color: #fff;
        border-color: #fff;
    }

    .piece.black {
        background-color: #000;
        border-color: #000;
    }

    .validMove {
        display: block;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        border: 5px solid #000;
        margin: auto;
        color: #fff;
    }

    .highlightSquare {
        left: 15px;
        top: 15px;
        right: 15px;
        bottom: 15px;
        position: absolute;
        border-radius: 50%;
        border: 5px solid red;
    }

    th {
        font-size: x-large;
    }

    th:first-child[scope="row"] {
        text-align: right;
    }

    th:last-child[scope="row"] {
        text-align: left;
    }
}

.boardComponent.whiteToPlay {
    .validMove {
        border-color: #fff;
    }
}

input.moveListBox {
    width: 100%;
}

@media only screen and (max-width: 500px) {
    .boardComponent {
        td {
            width: 35px;
            height: 35px;
        }

        .piece {
            height: 20px;
            width: 20px;
        }

        .validMove {
            height: 10px;
            width: 10px;
        }

        .highlightSquare {
            left: 10px;
            top: 10px;
            right: 10px;
            bottom: 10px;
            border: 5px solid red;
        }
    }
}

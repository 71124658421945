.btn-menu {
    display: block;
    position: relative;
    padding: 0;
    border: 0;
    background-color: transparent;
    border: 1px solid rgba(250, 250, 253,1);
    border-radius: 25%;
    box-shadow: 0 0.25em 0.25em rgba(0, 0, 0, 0.3);
    transition: box-shadow 0.8s ease-out, background-color 0.8s ease-out;
    cursor: pointer;

    ::before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        /*border: 1px solid #fff;*/
        /*border-radius: 50%;*/
    }

    &[aria-pressed=false] .svg-menu {
        .x {
            opacity: 0;
        }
    }

    &[aria-pressed=true] .svg-menu {
        transform: rotate(225deg);

        .h {
            backface-visibility: hidden;
            opacity: 0;
        }

        .t {
            transform: translate(0, 8px);
            transform: translateY(8px);
        }


        .b {
            transform: translate(0, -8px);
            transform: translateY(-8px);
        }
    }

    :hover,
    :focus {
        /*box-shadow: 0 0.5em 0.5em rgba(0, 0, 0, 0.4);*/
    }

    :active {
        box-shadow: none;
    }

    span {
        position: absolute;
        text-indent: -9999px;
    }
}


.svg-menu {
    display: block;
    width: 2.38em;
    height: 2.38em;
    margin: 0.25em;
    stroke-width: 5;
    stroke-linecap: square;
    stroke: gray;
    transition: stroke 0.8s ease-out, transform 0.8s ease-in-out;

    .btn-menu:hover &,
    .btn-menu:focus & {
        stroke: green;
    }

    path {
        backface-visibility: hidden;
        transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
    }
}

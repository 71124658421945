.menu-popup {
    position: fixed;
    top: 50px;
    right: 0;
    width: 70%;
    height: 100%;
    background-color: #323232;
    z-index: 1000;
    overflow-x: hidden;
    padding-top: 20px;
    transition: transform .2s ease-in-out;

    nav {
        display: flex;
        flex-direction: column;
    }

    ul {
        list-style-type: none;
        margin: 0px;
        padding: 0px;
    }

    li {
        border-top: 1px solid #646464;
        padding: 8px 0 8px 10px;

        a {
            color: #e0e0e0;
            text-decoration: none;
            font-size: 1.5rem;
        }

        :hover {
            background-color: #646464;
        }
    }

    > button {
        position: absolute;
        top: 10px;
        right: 10px;
        background-color: transparent;
        color: #e0e0e0;
        border: none;
        font-size: 2rem;
        cursor: pointer;
    }

    .closed {
        transform: translateX(100%);
    }
}

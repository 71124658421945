.actionButton {
    @at-root {
        button#{&}, input[type="submit"]#{&}, input[type="button"]#{&} {
            background-color: green;
            color: #323232;
            padding: 1em 1em;
            border: none;
            border-radius: 0.8em;
            transition: transform .2s;
            cursor: pointer;
            width: 100%;
            margin: 2em;
            display: block;
            font-weight: bold;
            margin-left: auto;
            margin-right: auto;

            &:hover {
                transform: scale(1.1);
            }
        }
    }
}
